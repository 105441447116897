<template>
  <v-card
    width="100%"
    flat
  >
    <v-row
      no-gutters    
      :style="{ height: '' }"
    >      
      <v-card-title :style="{ borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.5vh' }">
        {{ title }}
      </v-card-title>
    </v-row>
    <v-row
      no-gutters
      :style="{ height: '88%' }"
      class="pt-2"
    >
      <v-col>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Imprime valor de produtos no orçamento:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="12"
            md="12"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            Modelo de impressão de orçamento:
          </v-col>
          <v-col
            sm="12"
            md="12"
            lg="5"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-radio-group
                v-model="row"
                row
              >
                <v-radio
                  label="Bobina 80mm"
                  value="bobina"
                />
                <v-radio
                  label="A4"
                  value="A4"
                />
                <v-radio
                  label="A5"
                  value="A5"
                />
                <v-radio
                  label="A4/Bobina"
                  value="A4bobina"
                />
              </v-radio-group>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="12"
            md="12"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            Modelo de impressão de consignação:
          </v-col>
          <v-col
            sm="12"
            md="12"
            lg="5"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-radio-group
                v-model="row"
                row
              >
                <v-radio
                  label="Bobina 80mm"
                  value="bobina"
                />
                <v-radio
                  label="A4"
                  value="A4"
                />
                <v-radio
                  label="A5"
                  value="A5"
                />
              </v-radio-group>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="12"
            md="12"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            Modelo de impressão de devolução de consignação:
          </v-col>
          <v-col
            sm="12"
            md="12"
            lg="5"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-radio-group
                v-model="row"
                row
              >
                <v-radio
                  label="Bobina 80mm"
                  value="bobina"
                />
                <v-radio
                  label="A4"
                  value="A4"
                />
              </v-radio-group>
            </v-row>
          </v-col>
        </v-row>
        <v-divider horizontal />
        <v-row no-gutters>
          <v-col
            sm="12"
            md="12"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            Modelo de impressão de pedido:
          </v-col>
          <v-col
            sm="12"
            md="12"
            lg="5"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-radio-group
                v-model="row"
                row
              >
                <v-radio
                  label="Bobina 80mm"
                  value="bobina"
                />
                <v-radio
                  label="A4"
                  value="A4"
                />
                <v-radio
                  label="A5"
                  value="A5"
                />
                <v-radio
                  label="A4/Bobina"
                  value="A4bobina"
                />
              </v-radio-group>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="12"
            md="12"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            Modelo de impressão da receita de óculos:
          </v-col>
          <v-col
            sm="12"
            md="12"
            lg="5"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-radio-group
                v-model="row"
                row
              >
                <v-radio
                  label="Bobina 80mm"
                  value="bobina"
                />
                <v-radio
                  label="A4"
                  value="A4"
                />
              </v-radio-group>
            </v-row>
          </v-col>
        </v-row>
        <v-divider horizontal />
        <v-row no-gutters>
          <v-col
            sm="12"
            md="12"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            Informações na impresão de pedido:
          </v-col>
          <v-col
            sm="12"
            md="12"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-radio-group
                v-model="row"
                row
              >
                <v-radio
                  label="Normal"
                  value="normal"
                />
                <v-radio
                  label="Resumido"
                  value="resumido"
                />
                <v-radio
                  label="Ambos"
                  value="ambos"
                />
                <v-radio
                  label="Simultaneamente"
                  value="simultaneamente"
                />
              </v-radio-group>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="12"
            md="12"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            Modelo impressão de romaneio de entrega:
          </v-col>
          <v-col
            sm="12"
            md="12"
            lg="5"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-radio-group
                v-model="row"
                row
              >
                <v-radio
                  label="Bobina 80mm"
                  value="bobina"
                />
                <v-radio
                  label="A4"
                  value="A4"
                />
              </v-radio-group>
            </v-row>
          </v-col>
        </v-row>
        <v-divider horizontal />
        <v-row no-gutters>
          <v-col
            sm="12"
            md="12"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            Modelo de impressão de termo de garantia:
          </v-col>
          <v-col
            sm="12"
            md="12"
            lg="5"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-radio-group
                v-model="row"
                row
              >
                <v-radio
                  label="Bobina 80mm"
                  value="bobina"
                />
                <v-radio
                  label="A4"
                  value="A4"
                />
                <v-radio
                  label="A5"
                  value="A5"
                />
              </v-radio-group>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="12"
            md="12"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            Modelo de impressão de ordem de serviço:
          </v-col>
          <v-col
            sm="12"
            md="12"
            lg="5"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-radio-group
                v-model="row"
                row
              >
                <v-radio
                  label="Bobina 80mm"
                  value="bobina"
                />
                <v-radio
                  label="A4"
                  value="A4"
                />
              </v-radio-group>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="12"
            md="12"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            Modelo de impressão de transferência:
          </v-col>
          <v-col
            sm="12"
            md="12"
            lg="5"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-radio-group
                v-model="row"
                row
              >
                <v-radio
                  label="Bobina 80mm"
                  value="bobina"
                />
                <v-radio
                  label="A4"
                  value="A4"
                />
              </v-radio-group>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="12"
            md="12"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            Modelo de impressão após salvar/editar kit:
          </v-col>
          <v-col
            sm="12"
            md="12"
            lg="5"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-radio-group
                v-model="row"
                row
              >
                <v-radio
                  label="Bobina 80mm"
                  value="bobina"
                />
                <v-radio
                  label="A4"
                  value="A4"
                />
              </v-radio-group>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="12"
            md="12"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            Impressão de senha pedido cliente e preparação:
          </v-col>
          <v-col
            sm="12"
            md="12"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-radio-group
                v-model="row"
                row
              >
                <v-radio
                  label="Senha de pedido para cliente"
                  value="senhacliente"
                />
                <v-radio
                  label="Pedido de preparação"
                  value="preparacao"
                />
                <v-radio
                  label="Ambos"
                  value="ambossenha"
                />
                <v-radio
                  label="Nenhum"
                  value="nenhumsenha"
                />
              </v-radio-group>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza impressão de balança:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza prefixo na etiqueta da balança:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <v-divider horizontal />
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Quantidade padrão para impressão de carnê:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-text-field
              outlined
              dense
              flat
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Quantidade padrão para impressão de recibo:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-text-field
              outlined
              dense
              flat
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {


    }
  },
  watch: {

  },
  mounted() {

  },
  methods: {

  },

}
</script>